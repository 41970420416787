<template>
  <DataEditor
    v-model="data"
    :items="items"
    :show-label="false"
    :uimodel="uimodel"
    :is-custom-editor="true"
    :loading="loading"
    :disabled="disabled"
    :data-test-id="'generic_select_' + uimodel.path"
    @restore="$emit('restore')"
    @remove="$emit('remove')"
    @focus="loadItems"
  />
</template>

<script>
import mixin from "../../../../../mixins/data-editor-mixin";

export default {
  mixins: [mixin],

  inject: {
    getPropertyData: {
      default() {
        console.warn("Parent does not provide getPropertyData function");
        return () => {
          return [];
        };
      },
    },
  },

  components: {
    DataEditor: () => import("../DataEditor.vue"),
  },

  props: {
    value: {
      type: String,
      required: false,
    },

    uimodel: {
      type: Object,
      required: true,
    },

    valueSource: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      data: this.value,
      items: [this.value],
    };
  },

  watch: {
    data(data) {
      // we prevent emitting when the parent is already undefined.
      // this also prevents a cycle where setting an object to inherit causes children to emit and the object comes back.
      if (data === undefined && this.uimodel.parentData === undefined) return;
      this.$emit("input", data);
    },

    "uimodel.data": function (data) {
      this.data = data;
    },

    value(value) {
      this.$set(this.uimodel, "data", value);
    },
  },

  methods: {
    async loadItems() {
      try {
        this.loading = true;
        const params = this.valueSource;
        const data = await this.getPropertyData(params);
        if (Array.isArray(data)) {
          const mapping = params?.fieldMapping;
          //create the items according to the given field mapping
          const items = data
            .filter((item) => {
              if (mapping.id) {
                return !!item?.[mapping.id];
              } else {
                return !!item;
              }
            })
            .map((item) => {
              if (!mapping?.id) {
                //if no id field is declared just push the whole item
                return item;
              }
              return {
                id: item[mapping?.id],
                name: item[mapping?.name],
              };
            });
          this.items = items;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
</style>